<template>
  <div>
    <div class="mainIndex" v-loading="loading" element-loading-text="加载中，请稍后">
      <div style="margin:20px 0 0 20px;border-bottom:1px solid #DBDBDB;">
        <span
          style="border-bottom:1px solid #00A1E9;display:inline-block;padding-bottom:5px;font-weight:500;color:#363E4D"
        >类别名称</span>
      </div>
      <div style="padding:10px 0 0 10px">
        <el-form label-width="100px" :model="basicData" :rules="enterRules" ref="industryRef">
          <el-form-item label="门类：" prop="largeCategory">
            <el-input v-model="basicData.largeCategory"></el-input>
          </el-form-item>
          <el-form-item label="门类说明：" prop="largeExplain">
            <el-input
              type="textarea"
              :autosize="{minRows:1,maxRows:8}"
              v-model="basicData.largeExplain"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-for="(item, index) in data" :key="index" style="padding:10px 0 0 10px  ">
        <el-form label-width="100px" :model="item">
          <el-form-item label="大类：">
            <el-input v-model="item.largeCategory"></el-input>
            <i @click="add(index)" class="el-icon-circle-plus-outline"></i>
          </el-form-item>
          <el-form-item label="大类说明：">
            <el-input v-model="item.largeExplain" type="textarea" :autosize="{minRows:1,maxRows:8}"></el-input>
          </el-form-item>
          <div
            v-for="(el, indexSmall) in item.childNode"
            :key="indexSmall"
            style="padding-left: 100px"
          >
            <el-form-item label="中类：">
              <el-input v-model="el.largeCategory"></el-input>
              <i @click="addmiddle(index)" class="el-icon-circle-plus-outline"></i>
            </el-form-item>
            <el-form-item label="中类说明：">
              <el-input v-model="el.largeExplain" type="textarea" :autosize="{minRows:1,maxRows:8}"></el-input>
            </el-form-item>
            <div v-for="(item1, index1) in el.childNode" :key="index1" style="padding-left: 100px">
              <el-form-item label="小类：">
                <el-input v-model="item1.largeCategory"></el-input>
                <i @click="addsmall(index, indexSmall)" class="el-icon-circle-plus-outline"></i>
              </el-form-item>
              <el-form-item label="小类说明：">
                <el-input
                  v-model="item1.largeExplain"
                  type="textarea"
                  :autosize="{minRows:1,maxRows:8}"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import { addIndustry, getIndustryOne, editIndustry } from '@/api/industry'
export default {
  computed: {
    personData() {
      return Number(this.$route.query.personData)
    },
    personType() {
      return Number(this.$route.query.personType)
    },
    Authorization() {
      return sessionStorage.getItem('token')
    },
  },
  data() {
    return {
      basicData: {
        categoryExplain: '',
        industryType: '',
      },
      loading: true,
      data: [
        // 大类别
        {
          largeExplain: '',
          largeCategory: '',
          childNode: [
            // 小类别
            {
              largeExplain: '',
              largeCategory: '',
              childNode: [
                // 子类别
                {
                  largeExplain: '',
                  largeCategory: '',
                },
              ],
            },
          ],
        },
      ],
      enterRules: {
        categoryExplain: [
          { required: true, message: '请填写门类说明', trigger: 'blur' },
        ],
        industryType: [
          { required: true, message: '请填写门类', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    if (this.personType) {
      this.solutionModules()
    }
  },
  methods: {
    updateCode() {},
    add() {
      const value = {
        largeExplain: '',
        largeCategory: '',
        childNode: [
          // 小类别
          {
            largeExplain: '',
            largeCategory: '',
            childNode: [
              // 子类别
              {
                largeExplain: '',
                largeCategory: '',
              },
            ],
          },
        ],
      }
      this.data.push(value)
    },
    addmiddle(indexBig) {
      const value = {
        largeExplain: '',
        largeCategory: '',
        childNode: [
          // 子类别
          {
            largeExplain: '',
            largeCategory: '',
          },
        ],
      }
      this.data[indexBig].childNode.push(value)
    },
    addsmall(indexBig, indexMiddle) {
      const value = {
        largeExplain: '',
        largeCategory: '',
      }
      this.data[indexBig].childNode[indexMiddle].childNode.push(value)
    },
    async saveItem() {
      // 编辑
      // const res = await editSolutionModule(this.data);
      this.$refs.industryRef.validate(async (valid) => {
        if (valid) {
          const params = {
            id: this.personData,
            industryKnowledgeCategoryResult: this.basicData,
          }
          if (this.personType) {
            const res = await editIndustry(params)
            if (res.code == 200) {
              this.$message.success('编辑成功')
            }
          } else {
            const res = await addIndustry(params)
            if (res.code == 200) {
              this.$message.success('添加成功 ')
            }
          }
        }
      })
    },
    cancelItem() {
      this.$router.go(-1)
    },
    async solutionModules() {
      const params = {
        id: this.personData,
      }
      const res = await getIndustryOne(params)
      if (res.code == 200) {
        ;(this.data = res.data.childNode), (this.basicData = res.data)
        this.loading = false
      }
    },
  },
  mounted() {
    document.getElementsByClassName('mainIndex')[0].style.height =
      document.body.clientHeight - 220 + 'px'
  },
}
</script>

<style lang="less" scoped>
/deep/.el-input {
  width: 470px !important;
}
/deep/.el-textarea {
  width: 80% !important;
  // min-height: 40px !important;
}
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
.mainIndex {
  background-color: white;
  // padding-top: 20px;
  // height: 730px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
